<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 提示信息 -->
            <alert-info :info="infodata"></alert-info>
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">其他相关规划信息</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.saveJson"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="数据密级" prop="sjmj">
                    <el-select
                        v-model="formdata.saveJson.sjmj"
                        placeholder="请选择数据密级"
                        :disabled="!isEdit"
                        @change="changeSJMJ"
                    >
                        <el-option label="公开" value="公开"></el-option>
                        <el-option label="不公开" value="不公开"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    v-show="formdata.saveJson.sjmj === '公开'"
                    label="发布范围"
                    prop="fbfw"
                >
                    <el-select
                        v-model="formdata.saveJson.fbfw"
                        placeholder="请选择发布范围"
                        :disabled="!isEdit"
                    >
                        <el-option
                            label="互联网公开"
                            value="互联网公开"
                        ></el-option>
                        <el-option
                            label="行业内部公开"
                            value="行业内部公开"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="类型" prop="lx">
                    <el-select
                        v-model="formdata.saveJson.lx"
                        placeholder="请选择类型"
                        filterable
                        clearable
                        :disabled="!isEdit"
                        @change="changeLX"
                    >
                        <el-option
                            v-for="(item, index) in lxList"
                            :key="index"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    v-if="formdata.saveJson.lx === '11'"
                    label="其他类型"
                >
                    <el-input
                        v-model="formdata.saveJson.qt"
                        :maxlength="50"
                        placeholder="按实际情况填写"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="规划期限" class="double_time">
                    <el-date-picker
                        v-model="formdata.saveJson.ghqxksnf"
                        clearable
                        value-format="yyyy"
                        type="year"
                        placeholder="开始时间"
                        :disabled="!isEdit"
                        @change="changeKssj"
                    ></el-date-picker>
                    <el-date-picker
                        v-model="formdata.saveJson.ghqxjsnf"
                        clearable
                        value-format="yyyy"
                        type="year"
                        placeholder="结束时间"
                        :disabled="!isEdit"
                        :picker-options="pickerOptionsend"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="名称" prop="mc">
                    <el-input
                        v-model="formdata.saveJson.mc"
                        :maxlength="50"
                        placeholder="请输入名称"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="组织编制单位">
                    <el-tooltip
                        effect="light"
                        content="填写规划组织编制单位"
                        placement="bottom"
                    >
                        <el-input
                            v-model="formdata.saveJson.zzbzdw"
                            :maxlength="50"
                            placeholder="组织编制单位"
                            :disabled="!isEdit"
                        ></el-input>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="编制单位">
                    <el-tooltip
                        effect="light"
                        content="填写规划的编制单位"
                        placement="bottom"
                    >
                        <el-input
                            v-model="formdata.saveJson.bzdw"
                            :maxlength="50"
                            placeholder="编制单位"
                            :disabled="!isEdit"
                        ></el-input>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="编制和公布状态" prop="bzhgbzt">
                    <el-select
                        v-model="formdata.saveJson.bzhgbzt"
                        filterable
                        clearable
                        placeholder="请选择编制和公布状态"
                        :disabled="!isEdit"
                    >
                        <el-option label="在编" value="0"></el-option>
                        <el-option label="通过审批待公布" value="3"></el-option>
                        <el-option label="已公布且现行" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <!-- v-if="formdata.saveJson.sjmj === '公开'"  :isEdit="!isEdit"-->
                <basic-upload
                    v-if="formdata.saveJson.sjmj === '公开'"
                    ref="uploadFile"
                    :itemId="itemId"
                    :metaData.sync="formdata.metaJson"
                    :basicType="basicType"
                    :isEdit="isEdit"
                >
                </basic-upload>
            </el-form>
        </div>
    </div>
</template>

<script>
import alertInfo from "@comp/alert_info";
import basicUpload from "@comp/basic_upload";
import snButton from "../0_com_js/edit-button.js";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "other_relative_planning",
    components: {
        alertInfo,
        basicUpload,
    },
    mixins: [infoMixin],
    data() {
        return {
            snButton: snButton,
            rules: {
                sjmj: [
                    {
                        required: true,
                        message: "请选择数据密级",
                        trigger: "blur",
                    },
                ],
                fbfw: [
                    {
                        required: true,
                        message: "请选择发布范围",
                        trigger: "blur",
                    },
                ],
                lx: [
                    {
                        required: true,
                        message: "请选择类型",
                        trigger: "change",
                    },
                ],
                mc: [
                    { required: true, message: "请输入名称", trigger: "blur" },
                ],
                bzhgbzt: [
                    {
                        required: true,
                        message: "请选择编制和公布状态",
                        trigger: "blur",
                    },
                ],
            },
            lxList: [
                {
                    label:
                        "其他文物保护规划(遗产要素以外的，其他单个、多个文物保护、利用规划)",
                    value: "0",
                },
                {
                    label: "遗址公园规划",
                    value: "1",
                },
                {
                    label: "历史文化名城、名镇、名村保护规划",
                    value: "2",
                },
                {
                    label: "风景名胜区规划",
                    value: "3",
                },
                {
                    label: "旅游规划",
                    value: "4",
                },
                {
                    label: "城市规划",
                    value: "5",
                },
                {
                    label: "土地利用规划",
                    value: "6",
                },
                {
                    label: "交通规划",
                    value: "7",
                },
                {
                    label: "水利规划",
                    value: "8",
                },
                {
                    label: "环保规划",
                    value: "9",
                },
                {
                    label: "林业规划",
                    value: "10",
                },
                {
                    label: "其他",
                    value: "11",
                },
            ],
            formdata: {
                saveJson: {
                    sjmj: "公开",
                    fbfw: "行业内部公开",
                    lx: "",
                    qt: "",
                    ghqxksnf: "",
                    ghqxjsnf: "",
                    mc: "",
                    zzbzdw: "",
                    bzdw: "",
                    bzhgbzt: "",
                },
                metaJson: [],
                heritageId: "",
                fileID: "",
                originalFileID: "",
                itemID: "26",
                userName: "",
                userId: "",
            },
            pickerOptionsend: {
                disabledDate: (time) => {
                    if (this.formdata.saveJson.ghqxksnf) {
                        return (
                            time.getFullYear() < this.formdata.saveJson.ghqxksnf
                        );
                    }
                },
            },
        };
    },
    computed: {},
    mounted() {},
    methods: {
        changeLX(value) {
            if (value !== "11") {
                this.formdata.qtlx = "";
            }
        },
        changeKssj() {
            let start = new Date(this.formdata.saveJson.ghqxksnf);
            let end = new Date(this.formdata.saveJson.ghqxjsnf);
            if (start.getTime() > end.getTime()) {
                this.formdata.saveJson.ghqxjsnf = "";
            }
        },
    },
};
</script>
<style lang="scss" scoped></style>
